.chatScreen__message {
    display: flex;
    align-items: end;
    flex-direction: column;
    padding: 25px 20px 0px;
    white-space: pre-line;
}

.date-separator {
    font-size: 14px;
    color: #7F8495;
    text-align: center;
    margin: 10px 0px;

}

.dialog-character {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px 0px;
}

.dialog-character h6 {
    font-size: 15px;
    font-weight: 600;
    color: #231916;
}

.dialog-character p {
    max-width: 73%;
    line-height: 150%;
    background-color: #ffffff;
    padding: 12px 16px;
    border: 1px solid #E5E5E5;
    -webkit-border-radius: 0px 16px 16px 16px;
    -moz-border-radius: 0px 16px 16px 16px;
    border-radius: 0px 16px 16px 16px;
    font-size: 16px;
    font-weight: 400;
}

.dialog-row {
    display: flex;
    align-items: flex-end;
    margin-left: 40px;

}

.dialog-row.user {
    flex-direction: column;
    gap: 8px 0px;
}

.dialog-row .chatScreen__paragraph {
    margin-right: 5px;
}

.dialog-row .chatScreen__timestamp {
    font-size: 12px;
    color: #A6A9B7;
}

.chatScreen__desc {
    text-align: center;
    font-size: 14px;
    color: #7F8495;
}

.chatScreen__message.has-inner {
    flex-direction: column;
    align-items: flex-start;
}

.chatScreen__message.has-inner .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
}

.chatScreen__message.selected {
    background-color: yellow;
}

.chatScreen__timpstamp {
    text-align: center;
    padding: 0px;
    color: gray;
}

.chatScreen__center {
    text-align: center;
    padding: 0px 8px;
    color: gray;
}

.chatScreen__paragraphUser {
    max-width: 100%;
    line-height: 150%;
    background-color: #ffffff;
    margin-left: 5px;
    padding: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 16px 0px 16px 16px;
    font-size: 16px;
    font-weight: 400;
    color: #000000D9;
}

.chatScreen__senderSelection {
    padding: 5px;
    position: fixed;
    width: 100%;
    bottom: 65px;
    text-align: center;
}

.chatScreen__senderSelection .MuiTabs-indicator {
    display: none !important;
}

.chatScreen__recommend.tarot {
    background-color: #ffffff;
}


.chatScreen__recommend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    gap: 8px 0px;
    width: 100%;
    overflow-x: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    z-index: 10;
}

.chatScreen__recommend--hidden {
    visibility: hidden;
    opacity: 0;
}

.chatScreen__recommend--visible {
    visibility: visible;
    opacity: 1;
}


.chatScreen__recommend .chat-btn{
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 10px;
}

.bottom-fixed {
    position: fixed;
    z-index: 100;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px 0px;
    max-width: 430px !important;
    width: 100% !important;
    padding: 0px 15px 20px 15px;
    background-color: #ffffff;
    box-sizing: border-box;
}

.bottom-fixed .star-point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.bottom-fixed .star-point span {
    font-size: 14px;
    color: #444444;
    font-weight: bold;
    margin-bottom: 5px;
}

.bottom-fixed .star-point button {
    width: 100px;
    border: none;
    font-size: 12px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(110, 60, 237);
    margin-top: 5px;
    cursor: pointer;
    height: 32px;
    text-align: center;
    border-radius: 8px;
}

.bottom-fixed {
    padding: 20px 15px;
}

.bottom-fixed.tarot {
    background-color: #F4F2F5;
}

.bottom-fixed.d-none {
    display: none;
}

.chatScreen__input-wrapper {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
}

.chatScreen__input {
    max-width: 100%;
    width: 100%;
    display: flex;
    gap: 0px 8px;
    height: 44px;
    padding: 0px !important;
    border-top: none;
    box-sizing: border-box;
}

.chatScreen__inputField {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    padding-left: 15px;
}

.chatScreen__content {
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    padding-top: 65px;
    padding-bottom: 130px;
    width: 100%;
}

.chatScreen__content.tarot .chat-btn.in-chat {
    border-color: #19DDC5;
    color: #19DDC5;
}

.chatScreen__content.tarot .chatScreen__paragraphUser {
    background-color: #ffffff;
}

.chatScreen__content.expand-height {
    padding-bottom: 30px !important;
}

.chatScreen__content.pb-0 {
    overflow: hidden !important;
}

.chatScreen__content.d-none {
    padding-bottom: 30px !important;
}

.chatScreen__senderSelection .MuiTab-root {
    width: 130px;
    min-width: unset !important;
}

.PrivateTabIndicator-colorPrimary-7,
.PrivateTabIndicator-colorPrimary-13,
.PrivateTabIndicator-colorPrimary-14,
.PrivateTabIndicator-colorPrimary-15,
.MuiTabs-indicator {
    background-color: #ffffff !important;
}

.chat-btn {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #19DDC5;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 400;
    color: #19DDC5;
    cursor: pointer;
}

.chat-btn.in-chat {
    border-color: #19DDC5;
    color: #19DDC5;
}

.chat-btn.d-none {
    display: none !important;
}

.chatScreen__input .send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px !important;
    height: 35px !important;
    box-sizing: border-box;
    background-color: #DBE2E4 !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px !important;
    border-radius: 100px !important;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
}

.chatScreen__input .short {
    width: 110px !important;
}

.send-btn .free-count {
    margin-right: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #00000073;
}

.send-btn .send-text {
    font-size: 16px;
    font-weight: 600;
    color: #3385FF;
}

#selectTarot {
    max-width: 430px;
    margin: 0px auto;
}

#selectTarot .tarot-contents {
    background: radial-gradient(78.59% 50% at 50% 50%, #68358D 0%, #2F1D35 100%);
    height: 100%;
}

#selectTarot .tarot-contents .tarot-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 65px;
}

#selectTarot .tarot-contents .tarot-contents-header img {
    color: #ffffff;
    cursor: pointer;
    margin-left: 10px;
}

#selectTarot .tarot-contents .tarot-contents-header p {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    margin-left: -10px;
}

#selectTarot .tarot-contents .tarot-contents-body {
    padding: 32px 16px;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards .selected-tarot-card-images {
    display: flex;
    align-items: center;
    gap: 0px 8px;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards .selected-tarot-card-images .selected-tarot-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 54px;
    height: 90px;
    background-color: #331D42;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    gap: 5px 0px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-sizing: border-box;
}


#selectTarot .tarot-contents .tarot-contents-body .selected-cards .selected-tarot-card-images .selected-tarot-card img {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards .selected-tarot-card-images .selected-tarot-card .number {
    font-size: 20px;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards .selected-tarot-card-images .selected-tarot-card.current {
    border: 1px solid #d9b6fc;
    background-color: #493055;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards .tarot-select-popover {
    position: relative;
    padding: 10px 14px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
    background-color: #63686F;
    box-sizing: border-box;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

#selectTarot .tarot-contents .tarot-contents-body .selected-cards .tarot-select-popover .box-arrow {
    position: absolute;
    top: 0px;
    left: 47%;
    width: 16px;
    height: 16px;
    transform: translate(0px, -50%) rotate(45deg);
    background-color: #63686F;
}


#selectTarot .tarot-contents .tarot-contents-body .tarot-cards {
    display: grid;
    grid-template-columns: repeat(6, 54px);
    grid-gap: 4px;
    overflow: auto;
    justify-content: center;
}

@media (min-width: 1024px) {
    #selectTarot .tarot-contents .tarot-contents-body .tarot-cards {
        max-height: 73vh; /* 화면 높이의 80%를 최대 높이로 설정 */
    }
}

/* 태블릿 화면 설정 */
@media (min-width: 768px) and (max-width: 1023px) {
    #selectTarot .tarot-contents .tarot-contents-body .tarot-cards {
        max-height: 70vh; /* 화면 높이의 70%를 최대 높이로 설정 */
    }
}

/* 모바일 화면 설정 */
@media (max-width: 767px) {
    #selectTarot .tarot-contents .tarot-contents-body .tarot-cards {
        max-height: 62vh; /* 화면 높이의 60%를 최대 높이로 설정 */
    }
}

#selectTarot .tarot-contents .tarot-contents-body .tarot-cards .tarot-grid-item {
    width: 54px;
    height: 90px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.tarot-hidden {
    visibility: hidden;
}

.tarot-confirm-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;
}

.tarot-confirm-modal p {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 8px;
}

.tarot-confirm-modal sub {
    font-size: 14px;
    font-weight: 400;
    color: #878789;
    text-align: center;
    margin-bottom: 24px;
    line-height: 21px;
}

.tarot-confirm-modal .btn-wrapper {
    display: flex;
    align-items: center;
    gap: 0px 8px;
    width: 100%;
}

.tarot-confirm-modal .btn-wrapper button {
    display: inline-flex;
    width: 50%;
    height: 40px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    align-items: center;
    border: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #BCC0C4;
    box-sizing: border-box;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    color: #00000073;
    cursor: pointer;
}

.tarot-confirm-modal .btn-wrapper button.pay {
    color: #ffffff;
    background-color: #8360E6;
    border-color: #8360E6;
}

.MuiDialog-paperScrollPaper {
    max-width: 380px !important;
    width: 80%;
}

.dv-star-rating-star i {
    font-size: 25px;
}

#callToInfluencer .MuiPaper-root {
    border-radius: 0px !important;
}

#callToInfluencer .cti-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background: radial-gradient(58.11% 34.41% at 49.87% 34.57%, rgba(25, 209, 221, 0.40) 0%, rgba(25, 221, 197, 0.08) 100%), #FFF;
    padding: 50px 0px;
    box-sizing: border-box;
}

#callToInfluencer .cti-container .cti-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
}

#callToInfluencer .cti-container .cti-image-box > img {
    width: 240px;
    height: 240px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    border-radius: 500px;
    object-fit: cover;
}

#callToInfluencer .cti-container .cti-state {
    height: 100px;
}

#callToInfluencer .cti-container .cti-name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 5px;
    margin-bottom: 100px;
}

#callToInfluencer .cti-container .cti-name img {
    width: 20px;
    height: 20px;
    margin-top: 4px;
}

#callToInfluencer .cti-container > p {
    color: #00000073;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 150px;
}

#callToInfluencer .cti-container .cti-bye {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    gap: 0px 70px;
}

#callToInfluencer .cti-container .cti-bye > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px 0px;
}

#callToInfluencer .cti-container .cti-bye .voice-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: none;
    -webkit-border-radius: 300px;
    -moz-border-radius: 300px;
    border-radius: 300px;
    background-color: greenyellow;
}

#callToInfluencer .cti-container .cti-bye .voice-btn img {
    width: 30px;
    height: 30px;
}

#callToInfluencer .cti-container .cti-bye img {
    width: 60px;
    height: 60px;
    cursor: pointer;
}

#callToInfluencer .cti-container .cti-bye p {
    color: #00000073;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
}

@keyframes breathe {
    0%, 100% { width: 60px; height: 60px; }
    50% { width: 70px; height: 70px; }
}

@keyframes character-breathe {
    0%, 100% { width: 240px; height: 240px; }
    50% { width: 250px; height: 250px; }
}