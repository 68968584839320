.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0px;
    position: relative;
    z-index: 1;
    background: transparent;
}

.header .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header__logo {
    height: 40px;
    object-fit: contain;
}

.header__icon {
    padding: 0px;
}

.header h6 {
    padding-left: 6px;
    font-size: 18px;
    font-weight: 700;
    color: rgba(0,0,0,0.85);
}