@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

/* libraries */
@import "simplebar-react/dist/simplebar.min.css";

/*@import "react-quill/dist/quill.snow.css";*/
/*@import "react-quill/dist/quill.bubble.css";*/

/* custom */
@import "setup.css";

/* plugins */
@import "plugins/swiper.css";
@import "plugins/simplebar.css";
@import "plugins/apex-charts.css";
@import "plugins/calendar.css";

*::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* Internet Explorer, Edge */
}

* {
    font-family: Pretendard, Apple SD Gothic Neo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important
}

.display-font {
    /*font-family: '궁서체' !important;*/
    font-family: 'Nanum Myeongjo', serif !important;
}

body {
    height: 100vh;
    overflow: auto;
    overscroll-behavior-y: none !important;
}

#root {
    max-width: 430px;
    height: 100%;
    margin: 0px auto;
}

#goodkingApp {
    position: relative;
    height: 100%;
    background-color: #ffffff;
    /*background-image: url("/public/fluming-bg.png");*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    overflow: unset !important;
}

#goodkingApp.chat-screen {
    background-color: #ffffff;
}

#goodkingApp .top-fixed {
    background-color: #ffffff;
}

#goodkingApp.chat-screen .top-fixed {
    background-color: #ffffff;
}

#goodkingApp.chat-screen.tarot,
#goodkingApp.chat-screen.tarot .top-fixed {
    background-color: #ffffff;
}

#goodkingApp.chat-screen.tarot .bottom-fixed {
    background-color: #ffffff;
}

.page-wrapper {
    padding: 0px 32px;
}

#homeScreen footer{
    position: fixed;
    max-width: 400px;
    width: 100%;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    height: auto;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.43);
    color: rgba(255, 255, 255, 0.45);
    font-size: 12px;
}

#homeScreen footer ul {
    font-size: 12px;
    padding: 0px 0px 0px 1rem;
}


#goodkingApp,
#adminApp,
#goodkingApp>div,
#adminApp>div{
    margin: 0px auto;
    max-width: 430px;
}


/* 공통 컴포넌트 */
.global-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
}

.global-input-wrapper .birthday-form {
    display: flex;
    gap: 0px 10px;
    margin-bottom: 5px;
}

.global-input-wrapper .birthday-form input {
    height: 38px;
    text-align: right;
}

.global-input-wrapper label {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #000000D9;
}

.global-input-wrapper .MuiInput-root {
    min-width: 100%;
    width: 100%;
}

@media (max-width: 768px) {

    .global-input-wrapper .MuiInputBase-root input,
    .global-input-wrapper .MuiInput-root input {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
    }

    #paymentsDialog {
        margin-bottom: 120px;
    }
}

.global-input-wrapper input {
    min-width: 100%;
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    border: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #F3F5F7;
    vertical-align: middle;
    font-size: 14px;
    text-align: left;
}

.global-input-wrapper input[type="checkbox"] {
    min-width: unset;
}

.global-input-wrapper .MuiTextField-root {
    width: 100%;
}

.global-input-wrapper .MuiInput-formControl:before,
.global-input-wrapper .MuiInput-formControl:after {
    display: none;
}

.global-input-wrapper input.error {
    outline: 1px solid #FD3D51;
    border: 1px solid #FD3D51;
}

.global-input-wrapper .error-text {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #FD3D51;
}

.global-radio-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 8px;
    width: 100%;
}

.global-radio-wrapper button {
    width: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: 1px solid #B5B9BD;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #00000073;
    cursor: pointer;
}

.global-radio-wrapper button.clicked {
    border-color: #19DDC5;
    color: #19DDC5;
}

.global-checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
}

.global-checkbox-wrapper input[type="checkbox"] {
    width: 13px;
    height: 13px;
    margin-right: 3px;
}

.global-checkbox-wrapper input[type="checkbox"]:checked ~ b {
    color: #19DDC5;
}

#adminApp,
#adminApp>div{
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
}



.header-tool-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 60px 0px 45px 0px;
}

.header-tool-box .right-icon {
    cursor: pointer;
}

.point-history {
    padding-bottom: 100px;
}

.point-history .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    font-size: 14px;
    font-weight: 500;
    color: #7F8495;
}

.point-history .no-data img {
    margin-bottom: 16px;
}

.point__timestamp {
    color: #000;
}

.point-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    padding: 0px 1rem;
    box-sizing: border-box;
    margin-top: 40px;
    margin-bottom: 15px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.point-history-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.point-box .number {
    display: inline-block;
    text-align: right;
}

.point-box .diamond {
    font-size: 26px;
    margin-bottom: 7px;
}

.point-box.history {
    height: 82px;
    margin-top: 0px;
    padding: 16px 24px;
    box-sizing: border-box;
    justify-content: flex-start;
    background-image: none !important;
    background-color: #ffffff;
    margin-bottom: 0px;
}

.point-box.history .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.point-box.history .left-side .number {
    width: 225px;
}

.recharging-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 9.5px 0px;
}

.recharging-text time {
    font-size: 14px;
    font-weight: 400;
    color: #00000073 !important;
}

.point-history-row .text {
    font-size: 14px;
    font-weight: 600;
    color: #000000D9 !important;
}

.point-history-row .number {
    font-size: 16px;
    font-weight: 600;
    color: #000000D9;
}

.point-history-row .number.plus {
    color: #19DDC5;
}

.recharge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 40px;
    border-radius: 15px;
    background-color: #fefffe;
    font-size: 14px;
    color: #d0272d;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #fafafa;
}

.chats.more {
    height: auto;
    /*padding: 0px 27.5px;*/
}

.chats.notification {
    height: 100%;
    background-color: #ffffff;
    margin-top: 25px;
    padding: 0px 35px;
}

.notification-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 70px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 60vh;
    padding-bottom: 100px;
}

.notification-wrapper hr {
    width: 100%;
    margin: 30px 0px;
    color: #7c7c7c;
}

.notification-box {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.notification-box img {
    margin-right: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.notification-box .notification-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100% - 120px);
}

.notification-box .notification-contents .notification-title {
    color: #000000;
    font-size: 17px;
    cursor: default;

}

.notification-box .notification-contents .notification-text {
    max-width: 100%;
    margin-top: 5px;
    color: #7a7a7a;
    font-size: 15px;
    cursor: default;
}

.notification-box time {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 11px;
    color: #636364;
}

.chats.more .chat {
    height: 100%;
}
a {
    text-decoration: none; /* remove underline */
    color: inherit;
}

.chat__details > p {
    color: gray;
}

.chat__image {
    margin-right: 20px;
}
.chat__timestamp {
    color: gray;
}

.chat {
    font-size: medium;
}

.chat__details > h2 {
    font-size: larger;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl:before {
    border-bottom: 1px solid;
    border-bottom-color: #9d9d9d;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl:after {
    border-bottom: 1px solid;
    border-bottom-color: #e79852;
}

.MuiTabs-root .Mui-disabled {
    opacity: 0.3 !important;
}

#orderTabs .MuiTabs-flexContainer {
    display: flex;
    flex-flow: column;
}

#orderTabs .PrivateTabIndicator-root-4 {
    display: none;
}

.MuiTabs-root .PrivateTabIndicator-root-1,
.MuiTabs-root .PrivateTabIndicator-root-2,
.MuiTabs-root .PrivateTabIndicator-root-3,
.MuiTabs-root .PrivateTabIndicator-root-4,
.MuiTabs-root .PrivateTabIndicator-root-5,
.MuiTabs-root .PrivateTabIndicator-root-6,
.MuiTabs-root .PrivateTabIndicator-root-7,
.MuiTabs-root .PrivateTabIndicator-root-8,
.MuiTabs-root .PrivateTabIndicator-root-9 {
    background-color: #D81A1D;
}

#orderButton {
    font-weight: bold;
    color: #D81A1D;
}

#categoryTabs .MuiTab-root.Mui-Selected{
    
}

.MuiTab-textColorPrimary {
    color: #000000 !important;
}

.MuiTab-textColorPrimary.Mui-selected {

}

.MuiTab-textColorPrimary.Mui-selected .MuiTab-wrapper {
    background-color: #d81a1d !important;
    color: #000000;
    border-radius: 10px;
}

.MuiTab-textColorPrimary.Mui-selected::after {
    border-bottom: 1px solid red;
}

#chatWrapper {
    justify-content: space-between;
    gap: 1rem;
    background-color: #ffffff;
}

#chatWrapper.tarot {
    background-color: #ffffff;
}

#chatWrapper>div {
    width: 100%;
}

.chatScreen {
    padding-top: 65px;
}

#chatWrapper>div .chatScreen__center {
    background-color: rgba(49, 46, 46, 0.77);
    color: #000000;
    padding: 10px;
}

.chatScreen__input .input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 60px);
    background: #F1F1F1;
    border-radius: 999px;
    font-size: 16px;
    box-sizing: border-box;
}

.chatScreen__input input {
    background-color: transparent !important;
}

.chatScreen__input .input-box input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #00000073;
}

#chatBox {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.top-fixed {
    position: fixed;
    width: 100%;
    z-index: 100;
}

.chat-character-box {
    position: relative;
    z-index: 100;
    display: flex;
    width: 92%;
    align-self: flex-end;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: -52px 0px 0px auto !important;
}

.chat-character-box .chat-character-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
    padding: 0px 16px 0px 10px;
    box-sizing: border-box;
}

.chat-character-box .chat-character-top button {
    height: 32px;
    margin-top: 2px;
    padding: 0px 12px;
    border: 1px solid #19DDC5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    color: #19DDC5;
    cursor: pointer;
}

.chat-character-box .chat-character-name {
    font-size: 18px;
    font-weight: 700;
    color: #000000D9;
}

.chat-character-box .chat-character-image {
    width: 90px;
    height: 90px;
    margin-left: -15px;
    margin-bottom: 10px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
}

.chat-character-box .chat-character-desc {
    font-size: 13px;
    color: #7d7d7d;
}

#dialogBox {
    z-index: 10;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 100%;
    overflow: unset !important;
}

@media only screen and (max-width: 767px) {
    #chatBox {
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: start;
    }

    #chatWrapper>div {
        width: 100%;
    }

    #dialogBox p {
        position: relative;
        z-index: 10;
    }

    #chatWrapper>div .chatScreen__center {
        z-index: 100;
    }
}

@media only screen and (min-width: 1536px) {

}

.page-contents-wrapper {
}

.page-contents-wrapper.more {
    display: flex;
    flex-direction: column;
    gap: 8px 0px;
    min-height: 100vh;
    background-color: #ffffff;
    overflow: unset;
    padding-bottom: 15rem;
}

.page-contents-wrapper#homeScreen {
    display: flex;
    flex-direction: column;
    background-color: #F7F8FA;
}

/*@media (max-width: 768px) {
    .page-contents-wrapper#homeScreen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
    }
}*/

.header {
    /* padding-top: 95px; */
}

.tinderCards__cardContainer {
    /*padding: 0px 27.5px;*/
}


.chatScreen>div,
.chatScreen>form {
    margin: 0px auto;
    max-width: 430px;
}

#characterList {
    overflow: unset !important;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: max-content;
    gap: 16px 0px;
    height: auto;
    min-height: calc(100vh - 200px);
    padding-top: 20px;
    background-color: #ffffff;
}

#characterList > div {
    width: 90%;
    margin: 0px auto;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
}

#characterList > div > a {

}

.MuiButton-contained {
    box-shadow: none !important;
    background-color: transparent !important;
    border-bottom: 3px solid #000000 !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.MuiButton-contained:active {
    box-shadow: none !important;
    background-color: transparent !important;
    border-bottom: 3px solid #000000 !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.signup-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    background-color: #D81A1D;
    cursor: pointer;
}

.signup-btn.full-width {

}

.signin-btn {
    border: none;
    background-color: transparent;
    font-size: 17px;
    color: #D81A1D;
    font-weight: bold;
    cursor: pointer;
}

.signup-btn.small {
    height: 50px;
    font-size: 15px;
}

.signin-btn.small {
    font-size: 15px;
}

.social-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    border: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    cursor: pointer;
}

.social-btn.kakao {
    background-color: #FEE500;
}

.social-btn.google {
    border: 1px solid #525252;
}

.social-btn.naver {
    background-color: #03C75A;
    color: #ffffff;
}

.MuiOutlinedInput-root.rounded {
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    border-radius: 10px !important;
}

.top-info-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    background-color: #ffffff;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 15px;
}

.user-info h6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
    color: #000000D9;
}

.user-info p {
    font-size: 14px;
    font-weight: 400;
    color: #00000073;
}

.user-info.not-login p {
    font-size: 16px;
}

.user-info.not-login {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user-info.not-login p b {
    font-weight: 600;
    color: #000000D9;
}

.user-info.login a.no-data {
    margin-left: 0px !important;
}

.user-info.not-login a {
    display: inline-block;
    width: auto;
    padding: 7px 20px;
    border: 1px solid #F4F4F5;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    box-shadow: 0px 1px 4px 0px #00000014;
    color: #19DDC5;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
}



.user-info p a {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #FF5471;
    cursor: pointer;
}

.ad-info {
    padding: 14px 17px;
    border: 1px solid #F2F3F3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000000A8;
}

.user-info .user-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FEE5DB;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    margin-bottom: 15px;
}

.user-info address {
    font-size: 13px;
    font-style: normal;
    color: #313131;
}

.cash-info {
    padding: 16px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    /*margin-bottom: 16px;*/
}

.cash-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #000000A6;
    margin-bottom: 16px;
}

.my-cash {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}

.my-cash span {
    font-size: 24px;
    font-weight: 700;
}

.my-cash .fluming-btn {
    width: 60px;
    height: 30px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: linear-gradient(180deg, #19DDC5 0%, #19C5DD 100%);
    font-size: 14px;
    font-weight: 600;
    margin-left: auto;
}

.more-item-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: unset;
    gap: 20px 0px;
}

.more-item-box .more-item {
    width: 91%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0px 30px;
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
}

.more-item-box .more-item h4 {
    font-size: 16px;
    font-weight: 500;
    color: #000000D9;
}

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.multi-line-text-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 표시할 줄의 수 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-list {
    overflow: hidden;
}

.chat-list-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: max-content;
    gap: 16px 0px;
    height: auto;
    min-height: 100%;
    position: relative;
    bottom: 60px;
    margin-top: 60px;
    padding-top: 8px;
    background-color: #ffffff;
}

.chat-list-box .chat-list-item-row {
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
}

.chat-list-box .chat-history-item {
    width: calc(100% - 67px);
    position: relative;
    box-sizing: border-box;
    color: #000000;
    overflow: hidden;
    border-bottom: none;
}

.chat__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px 0px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.chat__details .top-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat__details .top-line h6 {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.chat__details .top-line .category {
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(180deg, #19DDC5 0%, #19C5DD 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.chat__details .middle-line {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
}

.chat__details .middle-line .last-dialog-text {
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
}

.chat__details .middle-line time {
    font-size: 11px;
    color: #000000;
}

.chat__details .bottom-line span {
    display: inline-block;
    white-space: nowrap;          /* 텍스트를 한 줄로 유지 */
    overflow: hidden;             /* 넘치는 텍스트를 숨김 */
    text-overflow: ellipsis;      /* 생략 부호를 표시 */
    max-width: 255px;
}

.chat__details .bottom-line {
    font-size: 14px;
    font-weight: 400;
    color: #000000A6;
}

.chat-list-box .point-history-item {
    background-color: #000000;
    height: auto !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: default;
}

.point__details {
    padding: 15px;
    position: relative;
    z-index: 1;
}

.point__details .top-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.point__details .top-line h6 {
    min-height: 20px;
    margin-right: 15px;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.point__details .top-line .category {
    font-size: 14px;
    font-weight: bold;
    color: #bea20b;
}

.point__details .middle-line {
    margin-bottom: 5px;
}

.point__details .middle-line .last-dialog-text {
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
}

.point__details .middle-line time {
    font-size: 11px;
    color: #000000;
}

.point__details .bottom-line {
    font-size: 14px;
    color: #ffe3af;
    line-height: 1.4;
    /*font-weight: bold;*/
    overflow: hidden; /* 내용을 넘어가는 부분은 숨깁니다. */
    text-overflow: ellipsis; /* 넘친 내용을 ...로 표시합니다. */
    display: -webkit-box; /* 브라우저 호환성을 위해 추가 */
    -webkit-line-clamp: 3; /* 표시할 줄 수를 설정합니다. 여기서는 3줄을 표시하도록 설정합니다. */
    -webkit-box-orient: vertical; /* 텍스트를 수직 방향으로 나타냅니다. */
}

.fluming-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    color: #ffffff;
    background: linear-gradient(180deg, #19DDC5 0%, #19C5DD 100%);
    cursor: pointer;
}

.fluming-btn.white {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: 1px solid #19DDC5 !important;
}

.fluming-sm-btn {
    height: 30px;
    display: inline-block;
    width: 100%;
    box-shadow: none;
    border: none;
    background-color: #496cc2;
    color: #ffffff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.fluming-sm-btn.red {
    background-color: #bb1717;
}

.tag-box.btn-box {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag-box.btn-box button{
    min-width: 100px;
    width: auto;
    max-width: unset;
    background-color: #949494;
    color: #ffffff;
}

.tag-box.btn-box button.contained{
    background-color: #496cc2;
}

#paymentsDialog {
    position: relative;
    height: 100%;
    background-color: #ffffff;
}

#paymentsDialog .fluming-footer {
    
}

#paymentsDialog .MuiDialog-paper {
    width: 100% !important;
}

#paymentsHistory .my-cash-wrapper,
#paymentsDialog .my-cash-wrapper {
    padding: 20px 16px;
    background-color: #fff;
    border-bottom: 1px solid #F4F4F5;
    margin-bottom: 8px;
}

#paymentsHistory .my-cash,
#paymentsDialog .my-cash {
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 17px;
    background-color: #F2F3F3;
    border: 1px solid #e2e5eb;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

#paymentsHistory .my-cash .left,
#paymentsHistory .my-cash .right,
#paymentsDialog .my-cash .left,
#paymentsDialog .my-cash .right {
    display: flex;
    align-items: center;
}

#paymentsHistory .my-cash .left,
#paymentsDialog .my-cash .left {
    color: #000000A6;
    font-weight: 600;
    font-size: 14px;
}

#paymentsHistory .my-cash .left img,
#paymentsDialog .my-cash .left img {
    margin-right: 8px;
}

#paymentsHistory .my-cash .right,
#paymentsDialog .my-cash .right {
    display: inline-flex;
    font-size: 16px;
    font-weight: 400;
}

#paymentsHistory .my-cash .right b,
#paymentsDialog .my-cash .right b {
    margin-right: 5px;
    font-size: 24px;
    font-weight: 700;
}

#paymentsDialog .payment-text-box {
    display: flex;
    align-items: center;
    padding: 20px 16px;
    border-top: 1px solid #F4F4F5;
    border-bottom: 1px solid #F4F4F5;
    background-color: #ffffff;
    box-sizing: border-box;
}

#paymentsDialog .payment-text-box strong {
    font-size: 16px;
    font-weight: 600;
}

#paymentsDialog .table-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 20px;
    border-bottom: 1px solid #F4F4F5;
}

#paymentsDialog .table-head p {
    font-size: 12px;
    font-weight: 400;
    color: #000000D9;
}

#paymentsDialog #cashTable {
    width: 100%;
    margin-bottom: 8px;
}

#paymentsDialog #cashTable .cash-row {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    background-color: #ffffff;
    margin-right: 0px !important;
    border-bottom: 1px solid #E2E5EB;
}

#paymentsDialog #cashTable .cash-row:last-child {
    border-bottom: 1px solid #F4F4F5;
}

#paymentsDialog #cashTable .cash-row > span:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 32px);
}

#paymentsDialog #cashTable .cash-row > span:nth-child(2) button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 92px;
    padding: 10px 16px;
    border: none;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-color: #19DDC5;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
}

#paymentsDialog .button-box {
    padding: 8px 16px;
    background-color: #ffffff;
    border-top: 1px solid rgba(0,0,0,0.15);
}

#paymentsDialog .button-box .fluming-btn {
    background-color: #19DDC5;
}

#paymentsDialog #payment-widget {
    border-top: 1px solid #F4F4F5;
    margin-bottom: 8px;
}

#paymentsDialog .payment-info {
    padding: 20px 16px;
    background-color: #ffffff;
}

#paymentsDialog .payment-info .pay-amount {
    display: flex;
    flex-direction: column;
    gap: 16px 0px;
}

#paymentsDialog .payment-info .pay-amount * {
    font-size: 16px;
    font-weight: 400;
}

#paymentsDialog .payment-info .pay-amount h6 {
    color: #000000D9;
    font-weight: 600;
}

#paymentsDialog .payment-info .pay-amount .pay-amount-detail {
    display: flex;
    flex-direction: column;
    gap: 8px 0px;
    padding-bottom: 16px;
    color: #000000A6;
    border-bottom: 1px solid #E2E5EB;
}

#paymentsDialog .payment-info .pay-amount .pay-amount-detail p {
    display: flex;
    justify-content: space-between;
}

#paymentsDialog .payment-info .pay-amount .total-amount {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 16px;
}

#paymentsDialog .payment-info .pay-amount .total-amount b {
    font-size: 18px;
    font-weight: 700;
    color: #000000D9;
}

#paymentsDialog .payment-info .pay-information {
    padding: 8px 16px 16px 16px;
    line-height: 150%;
    font-size: 14px;
    font-weight: 400;
    color: #00000073;
}

#paymentsDialog .payment-info .pay-information ul {
    padding: 0px;
}

#paymentsDialog .payment-info .pay-information ul li {
    list-style-type: disc;
}

.policy-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 16px;
    border-bottom: 1px solid #E2E5EB;
    box-sizing: border-box;
}

.policy-tabs a {
    margin: 0px 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    color: #00000073;
}

.policy-tabs a.selected {
    color: #000000D9;
    border-bottom: 2px solid #000000D9;
}
#__next > div > * {
    padding: 0px !important;
}

.coin-tab .MuiTab-wrapper {
    flex-direction: row !important;
    gap: 0px 5px
}

.character-tabs {
    display: flex;
    min-height: 47px;
    height: 47px;
    padding: 0px 16px;
    gap: 0px 16px;
    border-bottom: 1px solid #f4f4f4;
    background-color: #ffffff;
}

.character-tabs .tab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-bottom: 2px solid transparent;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    cursor: pointer;
}

.character-tabs .tab.select {
    color: #19DDC5;
    border-bottom: 2px solid  #19DDC5;;
}

.character-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 126px;
    padding: 8px 0px;
}

.character-info.chat {
    display: flex;
    flex-direction: row !important;
    padding: 30px 20px 8px !important;
    align-items: center !important;
    justify-content: flex-start !important;
    height: auto !important;
}

.character-info.unse {
    width: 100%;
}

.character-info.unse .character-list--item {
    font-weight: normal;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
}

.character-info.unse .bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.character-info.unse .bottom-line .star-point span{
    color: #343434;
    font-size: 14px;
    font-weight: bold;
}

.shop-bottom-sheet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 0px;
}

.category-title {
    display: flex;
    gap: 0px 8px;
    align-items: center;
    margin-bottom: 7.5px;
}

.category-title .category {
    display: inline-flex;
    align-items: center;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: 600;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #19DDC5;
    color: #ffffff;
}

.character-list--item {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
}

.description {
    max-width: 185px;
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 14px;
    color: rgba(0,0,0,0.65);
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 표시할 줄의 수 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
}

.pricing {
    display: flex;
    align-items: center;
}

.tags {
    display: flex;
    align-items: center;
    margin-top: auto;
    gap: 0px 8px;
}

.tags span {
    color: #FF5471;
    font-size: 12px;
    font-weight: 400;

}

.profile-tabs {
    color: #000000
}

.profile-tabs .MuiTabs-flexContainer {
    flex-wrap: wrap;
}

.name-change input {
    margin-top: 5px;
    color: #000000;
}

.character-price {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px 5px 10px;
    background-color: rgba(0, 0, 0, 0.73);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: #000000;
}

.chat-list-page {
    padding-top: 0px;
    padding-bottom: 10rem;
}

.edit-character .MuiFormGroup-root {
    padding: 0px 20px;
}

.edit-character .MuiFormGroup-root > * {
    width: 100%;
}

.edit-character legend {
    color: #000000;
}


.edit-character input,
.edit-character textarea {
    color: #000000;
    border: 1px solid #000000;
    padding: 10px;
}


#tingLock.unlock {
    filter: blur(0px) !important;
}

#tingLock * {
    color: black;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.MuiIconButton-root:hover {
    background-color: transparent !important;
}



#emailSignIn {
    background-color: #ffffff;
}

#emailSignIn .MuiBackdrop-root {
    background-color: #ffffff;
}

#emailSignIn .MuiDialog-container {
    display: block;
}

#emailSignIn .MuiPaper-elevation {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: unset;
    width: 100%;
    max-height: unset;
    height: 100%;
    margin: 0px;
    text-align: center;
    box-shadow: none;
}

#emailSignForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 430px;
    width: 100%;
    height: 100%;
    padding: 0px 32px;
    box-sizing: border-box;
}

#emailSignForm.display-none {
    display: none !important;
}

#emailSignForm .back-btn {
    display: inline-flex;
    align-items: center;
    height: 50px;
    margin-bottom: 100px;
    padding: 0px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    color: #19DDC5;
    cursor: pointer;
}

#emailSignForm form {
    width: 100%;
}

#emailSignForm form .form-wrapper h6 {
    margin-bottom: 56px;
    font-size: 20px;
    font-weight: 700;
    color: #000000D9;
    text-align: center;
}

#emailSignForm form .form-wrapper .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
}

#emailSignForm form .form-wrapper label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #000000D9;
}

#emailSignForm form .form-wrapper input {
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    border: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #F3F5F7;
}

#emailSignForm form .form-wrapper input.error {
    outline: 1px solid #FD3D51;
    border: 1px solid #FD3D51;
}

#emailSignForm form .form-wrapper .error-text {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #FD3D51;
}

#emailSignForm form .form-wrapper .signin-btn {
    display: block;
    width: 100%;
    height: 56px;
    margin-bottom: 24px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background-color: #BCC0C4;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    cursor: default;
}

#emailSignForm form .form-wrapper .signin-btn.filled {
    background-color: #19DDC5;
    cursor: pointer;
}

#emailSignForm .btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 24px;
}

#emailSignForm .btn-box a {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    color: #00000073;
}

#myInfo.tarot .fluming-btn {
    background-color: #8360E6;
}

#myInfo.tarot .global-radio-wrapper button.clicked {
    border-color: #19DDC5;
    color: #19DDC5;
}

#myInfo.tarot .global-checkbox-wrapper input[type="checkbox"]:checked ~ b {
    color: #8360E6;
}

#myInfo.chat .MuiPaper-root{
    min-width: 350px;
}

#myInfo.tarot .input-birthdate,
#myInfo.tarot .input-solar,
#myInfo.tarot .input-birthtime,
#myInfo.chat .input-birthdate,
#myInfo.chat .input-solar,
#myInfo.chat .input-birthtime {
    display: none;
}

#myInfo .MuiPaper-root {
    display: flex;
    flex-direction: column;
    gap: 20px 0px;
    padding: 24px;
    width: 100%;
    max-width: 340px;
    box-sizing: border-box;
}

#myInfo .MuiPaper-root > h2,
#myInfo .MuiPaper-root > div {
    padding: 0px;
}

#myInfo .MuiPaper-root > h2 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

#myInfo .MuiPaper-root > .MuiDialogContent-root .global-input-wrapper {
    margin-bottom: 12px;
}

#fortunePaper {
    position: relative;
    z-index: 10;
    top: -100px;
    bottom: 0px;
    background-color: #ffffff;
}

#fortunePaper .paper-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 190px;
    margin-bottom: 24px;
    background-size: cover ;
    background-repeat: no-repeat;
    backdrop-filter: invert(20%);
    color: #ffffff;
}

#fortunePaper .paper-info img {
    margin-bottom: 16px;
}

#fortunePaper .paper-info h6 {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
}

#fortunePaper .paper-info time {
    font-size: 14px;
    font-weight: 500;
}

#fortunePaper .paper-contents-wrapper {
    margin: 8rem 24px 24px;
    padding: 24px 16px;
    border: 1px solid #F3F3F3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);
}

#fortunePaper .paper-contents-wrapper .paper-owner {
    margin-bottom: 24px;
    text-align: center;
}

#fortunePaper .paper-contents-wrapper .paper-owner p {
    font-size: 12px;
    color: #888888;
    font-weight: 350;
    margin-bottom: 8px;
}

#fortunePaper .paper-contents-wrapper .paper-owner b {
    font-size: 16px;
    color: #231916;
    margin-bottom: 8px;
}

#fortunePaper .paper-contents-wrapper .paper-contents {
    line-height: 150%;
    margin-bottom: 8px;
    font-size: 14px;
    color: #5D5D5D;
}

.fluming-footer {
    /*position: sticky;
    position: -webkit-sticky;
    bottom: 23px;
    left: 0px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.fluming-footer .fluming-footer--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 16px;
    border-bottom: 1px solid #E2E5EB;
    box-sizing: border-box;
}

.fluming-footer .fluming-footer--header.info-open {
    border-bottom: none;
}

.fluming-footer .fluming-footer--header button {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 500;
    color: #00000073;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.fluming-footer .fluming-footer--header button img {
    margin-left: 2px;
}

.fluming-footer .fluming-footer--header button img.info-open {
    transform: rotate(180deg);
}

.fluming-footer .fluming-footer--contents {
    display: flex;
    flex-direction: column;
    gap: 8px 0px;
    padding: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #00000073;
}

.fluming-footer .fluming-footer--contents .link-row {
    display: flex;
    gap: 8px;
    font-weight: 700;
}

.fluming-footer .fluming-footer--contents .info-row {
    line-height: 140%;
}

.fluming-footer .blank {
    height: 48px;
}

.fixed-email {
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #F3F5F7;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    cursor: default;
}

.remove-id {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.remove-id p {
    font-size: 14px;
    font-weight: 400;
    color: #00000073;
}

.remove-id button {
    font-size: 14px;
    font-weight: 600;
    color: #00000073;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.MuiDrawer-modal {
    margin: 0px auto;
    max-width: 430px;
}

.MuiDrawer-modal .MuiBackdrop-root {
    margin: 0px auto;
    max-width: 430px;
}

.MuiDrawer-modal .MuiDrawer-paper {
    margin: 0px auto;
    max-width: 430px;
    box-shadow: unset !important;
    -webkit-border-radius: 16px 16px 0px 0px;
    -moz-border-radius: 16px 16px 0px 0px;
    border-radius: 16px 16px 0px 0px;
}

.MuiDrawer-modal .MuiDrawer-paper .another-character-wrapper .character-info {
    flex-direction: column;
}

.MuiDrawer-modal .MuiDrawer-paper .character-info {

}

.MuiDrawer-modal .MuiDrawer-paper .payment-info {
    padding: 12px 20px;
    box-sizing: border-box;
}

.payment-info.tarot .fluming-btn {
    background-color: #8360E6 !important;
}

.fortune-paper {
    height: 0px;
    overflow: hidden;
    padding: 0px 16px;
}

.fortune-paper.open {
    height: auto;
    overflow: visible;
    padding: 0px 16px;
    transition: 0.2s;
}

.home-my-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0px 16px;
    margin-bottom: 24px;
    background-color: #F2F3F3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.home-my-info p {
    font-size: 14px;
    font-weight: 400;
    color: #000000A6;
}

.home-my-info svg {
    color: #6a6c73;
    cursor: pointer;
}

.today-fortune {
    display: flex;
    flex-direction: column;
    gap: 0px 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.today-fortune img {
    width: 28px;
}

.today-fortune .today-fortune-contents {
    display: flex;
    gap: 0px 30px;
    margin-bottom: 24px;
}

.today-fortune .today-fortune-contents .today-fortune-text {
    display: flex;
    flex-direction: column;
    width: 210px;
}

.today-fortune .today-fortune-contents .today-fortune-text .today-text {
    font-size: 14px;
    font-weight: 400;
    color: #000000A6;
    margin-bottom: 16px;
}

.today-fortune .today-fortune-contents .today-fortune-text .name-text {
    margin-bottom: 5px;
}

.today-fortune .today-fortune-contents .today-fortune-text p {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* 표시할 줄의 수 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.today-fortune .today-fortune-contents .today-fortune-score {
    width: calc(100% - 210px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
}

.today-fortune .today-fortune-contents .today-fortune-score .CircularProgressbar-text {
    font-weight: 700;
}

.today-fortune .today-fortune-contents b {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #000000A6;
}

.today-fortune .today-fortune-contents b strong {
    font-weight: 600;
    color: #FF5471;
}

.today-fortune .today-fortune-contents b img {
    width: 25px;
    transform: rotate(180deg);
}

.today-fortune .today-fortune-contents p {
    font-size: 16px;
    font-weight: 600;
    color: #000000D9;
    max-width: 100%;
    line-height: 25px;
}

.today-fortune .go-to-detail {
    width: 100%;
    height: 48px;
    border: 1.4px solid #19DDC5;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    color: #19DDC5;
    cursor: pointer;
}

#todayFortunePaper {
    width: 100%;
    max-width: 430px;
    height: 100%;
    background-color: #ffffff;
}

#todayFortunePaper.d-none {
    display: none;
}


#todayFortunePaper .MuiIconButton-root {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

#todayFortunePaper .today-contents {
    padding: 16px 24px;
}

#todayFortunePaper .today-contents .my-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    background-color: #f5f5f5;
    border: 1px solid #f3f3f3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000000A6;
}

#todayFortunePaper .today-contents .fortune-info {
    font-size: 14px;
    font-weight: 400;
    color: #000000A6;
    margin-bottom: 40px;
}

#todayFortunePaper .today-contents .share-fortune {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 2px 10px 0px #00000012;
    margin-bottom: 40px;
}

#todayFortunePaper .today-contents p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

#todayFortunePaper .today-contents sub {
    font-size: 14px;
    font-weight: 400;
    color: #00000073;
    margin-bottom: 16px;
}

#todayFortunePaper .today-contents .share-images {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 8px;
}

#todayFortunePaper .today-contents .share-images .share {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: #63686F;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    cursor: pointer;
}

#todayFortunePaper .today-contents .share-images .share.kakao {
    background-color: #FEE500;
}

.login-plz {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 430px;
    padding: 10px 16px;
    background-color: #000000;
    color: #ffffff;
    box-sizing: border-box;
}

#todayFortunePaper .today-contents .another-character-wrapper h6 {
    font-size: 18px;
    font-weight: 700;
    color: #000000D9;
    margin-bottom: 24px;
}

#todayFortunePaper .today-contents .another-characters {
    display: flex;
    align-items: flex-start;
    height: 300px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 0px 16px;
    user-select: none;  /* 텍스트 선택 방지 */
    cursor: grab;  /* 마우스 커서 모양 변경 */
}

#todayFortunePaper .today-contents .another-characters:active {
    cursor: grabbing;
}

.login-plz.d-none {
    display: none;
}

.login-plz .MuiSvgIcon-root {
    margin-right: 5px;
    cursor: pointer;
}

.login-plz button {
    border: none;
    background-color: transparent;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.ellipsis {
    white-space: nowrap; /* 텍스트를 줄바꿈 없이 한 줄로 처리 */
    overflow: hidden;    /* 컨텐츠 영역을 넘어가는 텍스트 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 '...'로 표시 */
}

#reviewPage {
    width: 100%;
    max-width: 430px;
    height: auto;
    background-color: #ffffff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

#reviewPage .MuiIconButton-root {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

#reviewPage form {
    width: 100%;
}

#reviewPage form .form-wrapper h6 {
    margin-bottom: 56px;
    font-size: 20px;
    font-weight: 700;
    color: #000000D9;
    text-align: center;
}

#reviewPage form .form-wrapper .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
}

#reviewPage form .form-wrapper label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #000000D9;
}

#reviewPage form .form-wrapper input {
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    border: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #F3F5F7;
    font-size: 16px;
}

#reviewPage form .form-wrapper input.error {
    outline: 1px solid #FD3D51;
    border: 1px solid #FD3D51;
}

#reviewPage form .form-wrapper .error-text {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #FD3D51;
}

#reviewPage form .form-wrapper .signin-btn {
    display: block;
    width: 100%;
    height: 56px;
    margin-bottom: 24px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background-color: #BCC0C4;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    cursor: default;
}

#reviewPage form .form-wrapper .signin-btn.filled {
    background-color: #19DDC5;
    cursor: pointer;
}

.blur {
    position: fixed;
    width: 430px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.82);
}

#todayFortunePaperWrapper .MuiDrawer-paper,
#surveyDrawer .MuiDrawer-paper {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
}

.sns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    padding: 16px 24px;
    gap: 0px 12px;
}

.sns {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px 0px #00000012;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.sns img {
    width: 36px;
    height: 36px;
    margin-bottom: 12px;
}

.sns h6 {
    font-size: 13px;
    font-weight: 600;
    color: #000000D9;
    margin-bottom: 8px;
}

.sns p {
    font-size: 10px;
    font-weight: 600;
    color: #00000073;
}

#chatWrapper ~ .MuiSnackbar-root {
    bottom: 70px !important;
}

#richModal {
    padding: 24px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    width: 90%;
    max-width: 340px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#richModal p {
    text-align: center;
    line-height: 160%;
    font-size: 20px;
}

#richModal p span {
    display: block;
    font-size: 13px;
    margin-bottom: 10px;
    color: #2c2c2c;
    font-weight: bold;
}

#richModal p strong {
    color: rgb(237, 80, 60);
}

#richModal button {
    width: 100%;
    border: none;
    font-size: 19px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(237, 80, 60);
    cursor: pointer;
    height: 48px;
    text-align: center;
    border-radius: 8px;
}

.recommend-fortunes {
    height: 330px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
}

.face-analyzing {
    display: flex;
    flex-direction: column;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 100%;
    max-width: 430px;
    height: 100vh;
    background-color: #ffffff;
    color: #000000;
}

.share-link {
    width: 250px;
    margin: 0px auto;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #3385FF;
    border-radius: 16px;
    background: #3385FF;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
}

.fluming-main-background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;
    height: 330px;
    margin-bottom: 16px;
    background-image: url("/public/fluming/shu/background.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}

.fluming-main-background img {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%);
    width: 355px;
}

.fluming-main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px 0px;
}

.fluming-main-info .main-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.85);
}

.fluming-main-info .main-name img {
    margin-left: 4px;
}

.fluming-main-info .hello-text {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
}

.album-btn {
    height: 30px;
    padding: 0px 15px;
    margin-top: 15px;
    border: none;
    border-radius: 6px;
    background: linear-gradient(180deg, #19DDC5 0%, #19C5DD 100%);
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.chatScreen__input .voice-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px !important;
    height: 44px !important;
    border: none !important;
    background-color: transparent;
    cursor: pointer;
}

.album-container {
    padding: 20px 20px;
}

.album-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.album-body {
    min-height: 500px;
}

.album-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.album-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.album-header h3 {
    margin: 0;
    font-size: 24px;
}

.album-header button {
    padding: 5px 10px;
    background-color: #ddd;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.album-body {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.album-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.album-item {
    text-align: center;
}

.album-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.album-item p {
    margin-top: 10px;
    font-size: 14px;
}