@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    font-family: "Poppins", sans-serif;
}

body {
    overflow-x: hidden;
}

.f-container {
    max-width: 800px;
    margin: 0 auto;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}