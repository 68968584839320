.profileChatHistoryScreen {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px !important;
}

.profileChatHistoryScreen .chatScreen__message {
    display: flex;
    align-items: center;
    padding: 10px;
    white-space: pre-line;
}

.profileChatHistoryScreen td {
    color: #000000;
}


.profileChatHistoryScreen  .chatScreen__message.selected {
    background-color: yellow;
}

.profileChatHistoryScreen  .chatScreen__paragraph {
    margin-left: 10px;
    background-color: lightgray;
    padding: 12px 16px;
    border-radius: 20px;
}

.profileChatHistoryScreen  .chatScreen__timpstamp {
    text-align: center;
    padding: 0px;
    color: gray;
}

.profileChatHistoryScreen .chatScreen__paragraphUser {
    margin-left: auto;
    background-color: #29b3cd;
    padding: 15px;
    border-radius: 20px;
    color: white; 
}

.profileChatHistoryScreen .chatScreen__senderSelection {
    padding: 5px;
    position: fixed;
    width: 100%;
    bottom: 50px;
    border-top: 1px solid lightgray;
    text-align: center;
}

.profileChatHistoryScreen .chatScreen__input {
    display: flex;
    padding: 5px;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid lightgray;
}

.profileChatHistoryScreen .chatScreen__inputField {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
}

.profileChatHistoryScreen .messages {
    flex: 1;
    overflow-y: hidden;
}

#editImage {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
}
